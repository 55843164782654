import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServiceBoxes from "../components/serviceBoxes/serviceBoxes"
import Cta from "../components/cta/cta"
import ClientList from "../components/clientList/clientList"

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <ServiceBoxes />
    <ClientList />
    <Cta />
  </Layout>
)

export default ServicesPage
